/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import { AppRoute } from '@/config/routes';
import { Container } from '@/ui/components/Container/Container';
import ActionButton from '@/ui/landing-v2/components/ActionButton/ActionButton';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { EventHighlightComponent } from './EventHighlightComponent/EventHighlightComponent';
import styles from './Hero.module.scss';

export const Hero = () => {
  const { t } = useTranslation(I18nNamespaces.Homepagev2);
  const router = useRouter();

  const handleJoinUs = () => {
    router.push(AppRoute.registration.userRegistration);
  };

  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.innerContainer}>
          <div className={styles.headerWrapper}>
            <div className={styles.header}>
              <p> {t('home.hero.header1')} </p>
              <span> {t('home.hero.header2')}</span>{' '}
            </div>
            <h4 className={styles.subHeader}>
              {t('home.hero.subheader')}
            </h4>
            <div className={styles.buttonsWrapper}>
              <ActionButton onClick={handleJoinUs} variant="primary">
                {t('home.hero.join.button')}
              </ActionButton>
              <a href="#for-whom">
                <ActionButton
                  onClick={() => router.push('#for-whom')}
                  variant="secondary"
                >
                  {t('home.hero.seemore.button')}
                </ActionButton>
              </a>
            </div>
          </div>

          <Image
            className={styles.image}
            width={600}
            height={592}
            priority
            src="/images/landing-v2/homepage-hero-section-image.webp"
            alt={'hero image'}
          />
        </div>
      </Container>
      <div className={styles.container}>
        <Container>
          <div className={styles.iconsWrapper}>
            <EventHighlightComponent
              text={t('home.hero.icon1.text')}
              imagePosition={'left'}
            >
              <Image
                className={styles.icon1}
                width={190}
                height={190}
                src={'/images/landing-v2/homepage-hero-icon1.webp'}
                alt={'hackathon icon'}
              />
            </EventHighlightComponent>
            <EventHighlightComponent
              text={t('home.hero.icon2.text')}
              imagePosition={'right'}
            >
              <Image
                className={styles.icon2}
                width={190}
                height={190}
                src={'/images/landing-v2/homepage-hero-icon2.webp'}
                alt={'hackathon icon'}
              />
            </EventHighlightComponent>
            <EventHighlightComponent
              text={t('home.hero.icon3.text')}
              imagePosition={'left'}
            >
              <Image
                className={styles.icon3}
                width={176}
                height={176}
                src={'/images/landing-v2/homepage-hero-icon3.webp'}
                alt={'hackathon icon'}
              />
            </EventHighlightComponent>
            <EventHighlightComponent
              text={t('home.hero.icon4.text')}
              imagePosition={'right'}
            >
              <Image
                className={styles.icon4}
                width={176}
                height={176}
                src={'/images/landing-v2/homepage-hero-icon4.webp'}
                alt={'hackathon icon'}
              />
            </EventHighlightComponent>
          </div>
        </Container>
      </div>
    </div>
  );
};
