import styles from './PrizeComponent.module.scss';

interface PrizeComponentProps {
  title: string;
  description: string;
}

export const PrizeComponent = ({
  title,
  description,
}: PrizeComponentProps) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.text}>{description}</p>
    </div>
  );
};
