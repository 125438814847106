import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Container } from '@/ui/components/Container/Container';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { PrizeComponent } from './PrizeComponent/PrizeComponent';
import styles from './Prizes.module.scss';
export const Prizes = () => {
  const { t } = useTranslation(I18nNamespaces.Homepagev2);
  const { isMobile, isDesktop } = useMediaQuery();
  return (
    <section className={styles.container} id="prizes">
      <Container>
        {isMobile && (
          <Image
            className={styles.image}
            width={343}
            height={293}
            src="/images/landing-v2/home-prizes-image-mobile.webp"
            alt={'prizes image'}
          />
        )}
        <div className={styles.header}>
          <h2 className={styles.title}>{t('home.prizes.header')}</h2>
          <h4 className={styles.subtitle}>
            {t('home.prizes.subheader')}
          </h4>
        </div>
        <div className={styles.wrapper}>
          {isDesktop && (
            <Image
              className={styles.image}
              width={598}
              height={342}
              src="/images/landing-v2/home-prizes-image.webp"
              alt={'prizes image'}
            />
          )}
          <div className={styles.prizesWrapper}>
            <PrizeComponent
              title={'🏆 JetBrains License'}
              description={
                'Licencje dla całego zespołu o wartości 500 euro.'
              }
            />
            <PrizeComponent
              title={'🎁 Niespodzianka specjalna'}
              description={'Specjalna nagroda główna.'}
            />
            <PrizeComponent
              title={'🎁 Niespodzianka I'}
              description={'Duża nagroda od naszych partnerów.'}
            />
            <PrizeComponent
              title={'🎁 Niespodzianka II'}
              description={'Mała nagroda od naszych partnerów.'}
            />
          </div>
        </div>
      </Container>
    </section>
  );
};
