import { Colors } from '@/ui/styles/colors';
import { MediaQuery, mq } from '@/ui/styles/mediaQueries';
import { css } from '@emotion/css';

export const scheduleItem = css({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 1,
  maxWidth: 280,
  width: '100%',
  gap: 40,
  marginBottom: '18px',
  [mq(MediaQuery.Mobile)]: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 'none',
    gap: '0',
    alignItems: 'center',
  },
});

export const step = css({
  display: 'flex',
  alignItems: 'center',
});

export const stepPoint = (isPassed: boolean) =>
  css({
    position: 'relative',
    transform: 'rotate(45deg)',
    flexShrink: 0,
    width: 23,
    height: 23,
    border: isPassed ? 'none' : `1px solid ${Colors.orange400}`,
    background: isPassed
      ? 'linear-gradient(141deg,#c32900 -22.97%,#fb5500 45.56%)'
      : '#f7f6ee',
    zIndex: 1,
  });

export const lineLeft = css({
  width: '35px',
  height: 1,
  background: Colors.orange400,
});

export const lineSmall = css({
  width: '12.5px',
  height: 1,
  background: Colors.orange400,
});

export const lineRight = css({
  display: 'flex',
  flexShrink: 1,
  width: '70px',
  height: 1,
  background: Colors.orange400,
});

export const stepLine = css({
  position: 'relative',
  width: '100%',
  height: 1,
  background: Colors.orange400,
  zIndex: 0,
});

export const infoBox = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 18,
  flexGrow: 1,
});

export const deadline = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
  maxHeight: 68,
  backgroundColor: Colors.white,
  minHeight: '50px',
  [mq(MediaQuery.Mobile)]: {
    width: '280px',
  },
  '> p': {
    padding: '15px 16px',
    color: Colors.gray800,
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '120%',
  },
});
