import { useMediaQuery } from '@/ui/hooks/useMediaQuery';
import { RankingHeader } from '@/ui/landing-v2/sections/common/Ranking/RankingHeader';
import { RankingItem } from '@/ui/landing-v2/sections/common/Ranking/RankingItem';
import { useFindUsersWithHackathonRatings } from '@nerdbord/graphql-api';
import styles from './Ranking.module.scss';

export const Ranking = ({
  rankingLength,
}: {
  rankingLength?: number;
}) => {
  const { isMobile } = useMediaQuery();
  const { ratings } = useFindUsersWithHackathonRatings(rankingLength);

  return (
    <div
      className={isMobile ? styles.containerMobile : styles.container}
    >
      <ul
        className={
          isMobile
            ? styles.rankingContainerMobile
            : styles.rankingContainer
        }
      >
        <RankingHeader />

        {ratings.map((user, idx) => (
          <RankingItem key={user.id} {...user} idx={idx} />
        ))}
      </ul>
    </div>
  );
};
