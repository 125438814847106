import { useMediaQuery } from '@/ui/hooks/useMediaQuery';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import styles from './OurPartners.module.scss';
import { PartnerLogo } from './PartnerLogo';
export const OurPartners = () => {
  const { isMobile, isDesktop } = useMediaQuery();
  const { t } = useTranslation(I18nNamespaces.Homepagev2);
  return (
    <div className={styles.container}>
      <Image
        className={styles.image}
        width={444}
        height={377}
        src={'/images/landing-v2/homepage-ourpartners-image.webp'}
        alt={'decoration'}
      />
      <div className={styles.wrapper}>
        <h2 className={styles.title}>
          {t('home.ourpartners.title')}
        </h2>
        <span className={styles.subtitle}>
          {t('home.ourpartners.subtitle')}
        </span>
        {isDesktop && (
          <div className={styles.partnersWrapper}>
            <a
              target={'_blank'}
              rel={'noreferrer'}
              href="https://devhunting.co"
            >
              <PartnerLogo
                imageUrl={'/images/landing-v2/gdh-logo.webp'}
                width={206}
                height={55}
              ></PartnerLogo>
            </a>
            <a
              target={'_blank'}
              rel={'noreferrer'}
              href="https://bruxa.co"
            >
              <PartnerLogo
                imageUrl={'/images/landing-v2/bruxa-logo.webp'}
                width={206}
                height={55}
              ></PartnerLogo>
            </a>
          </div>
        )}
        {isMobile && (
          <div className={styles.partnersWrapper}>
            <a
              target={'_blank'}
              rel={'noreferrer'}
              href="https://devhunting.co"
            >
              <PartnerLogo
                imageUrl={'/images/landing-v2/gdh-logo.webp'}
                width={164}
                height={43}
              ></PartnerLogo>
            </a>
            <a
              target={'_blank'}
              rel={'noreferrer'}
              href="https://bruxa.co"
            >
              <PartnerLogo
                imageUrl={'/images/landing-v2/bruxa-logo.webp'}
                width={164}
                height={43}
              ></PartnerLogo>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
