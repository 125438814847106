import { Container } from '@/ui/components/Container/Container';
import { useMediaQuery } from '@/ui/hooks/useMediaQuery';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import FeedbackComponent from './FeedbackComponent/FeedbackComponent';
import styles from './Impressions.module.scss';
export const Impressions = () => {
  const { isMobile, isDesktop } = useMediaQuery();
  const { t } = useTranslation(I18nNamespaces.Homepagev2);
  return (
    <div className={styles.container}>
      {isDesktop && (
        <Container>
          <div className={styles.wrapper}>
            <Image
              width={387}
              height={930}
              src={
                '/images/landing-v2/homepage-impressions-image.webp'
              }
              alt={'decoration'}
            />
            <div className={styles.feedbackSection}>
              <div className={styles.header}>
                <h2 className={styles.title}>
                  {t('home.impressions.title')}
                  <p>✌</p>
                </h2>
                <h4 className={styles.subtitle}>
                  {t('home.impressions.subtitle')}
                </h4>
                <div className={styles.feedbackWrapper}>
                  <FeedbackComponent
                    text={t('home.impressions.feedback1')}
                  />
                  <FeedbackComponent
                    text={t('home.impressions.feedback2')}
                  />
                  <FeedbackComponent
                    text={t('home.impressions.feedback3')}
                  />
                  <FeedbackComponent
                    text={t('home.impressions.feedback4')}
                  />
                  <FeedbackComponent
                    text={t('home.impressions.feedback5')}
                  />
                  <FeedbackComponent
                    text={t('home.impressions.feedback6')}
                  />
                  <FeedbackComponent
                    text={t('home.impressions.feedback7')}
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}

      {isMobile && (
        <div className={styles.wrapper}>
          <div className={styles.feedbackSection}>
            <div className={styles.header}>
              <Container>
                <h2 className={styles.title}>
                  {t('home.impressions.title')}
                  <p>✌</p>
                </h2>
                <h4 className={styles.subtitle}>
                  {t('home.impressions.subtitle')}
                </h4>
              </Container>
            </div>{' '}
            <Image
              width={119}
              height={287}
              src={
                '/images/landing-v2/homepage-impressions-image-mobile.webp'
              }
              alt={'decoration'}
            />
          </div>
          <div className={styles.feedbackWrapper}>
            <div className={styles.feedbackMobileLine}>
              <FeedbackComponent
                text={t('home.impressions.feedback1')}
              />
              <FeedbackComponent
                text={t('home.impressions.feedback2')}
              />
              <FeedbackComponent
                text={t('home.impressions.feedback3')}
              />
              <FeedbackComponent
                text={t('home.impressions.feedback4')}
              />
            </div>
          </div>
          <div className={styles.feedbackWrapper}>
            <div className={styles.feedbackMobileLine}>
              <FeedbackComponent
                text={t('home.impressions.feedback5')}
              />
              <FeedbackComponent
                text={t('home.impressions.feedback6')}
              />
              <FeedbackComponent
                text={t('home.impressions.feedback7')}
              />
              <FeedbackComponent
                text={t('home.impressions.feedback1')}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
