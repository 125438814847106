import { useMediaQuery } from '@/ui/hooks/useMediaQuery';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import ActionButton from '../ActionButton/ActionButton';
import * as styles from './WorkshopsCard.styles';
interface WorkshopsCardProps {
  imageUrl: string;
  title: string;
  firstContent: string;
  secondContent: string;
  workshopUrl: string;
}

export const WorkshopsCard = ({
  imageUrl,
  title,
  firstContent,
  secondContent,
  workshopUrl,
}: WorkshopsCardProps) => {
  const { t } = useTranslation(I18nNamespaces.Homepagev2);
  const router = useRouter();
  const { isMobile, isDesktop } = useMediaQuery();
  return (
    <div
      className={styles.container}
      onClick={() => router.push(workshopUrl)}
    >
      <Image
        className={styles.logoImage}
        width={isDesktop ? 116 : 66}
        height={isDesktop ? 166 : 66}
        src={imageUrl}
        alt={`workshop ${title} logo`}
      />
      <div className={styles.wrapper}>
        <span className={styles.contentHeading}>{title}</span>
        <div>
          <p className={styles.content}>{firstContent}</p>
          <p className={styles.content}>{secondContent}</p>
        </div>
        <div className={styles.buttonWrapper}>
          <ActionButton
            onClick={() => router.push(workshopUrl)}
            variant="secondary"
          >
            {t('component.workshopsCard.button')}
          </ActionButton>
        </div>
      </div>
    </div>
  );
};
