import styles from './FeedbackComponent.module.scss';

interface FeedbackComponentProps {
  text: string;
}
export const FeedbackComponent = ({
  text,
}: FeedbackComponentProps) => {
  return <div className={styles.container}>{text}</div>;
};

export default FeedbackComponent;
