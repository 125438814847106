import { AppRoute } from '@/config/routes';
import { useMediaQuery } from '@/ui/hooks/useMediaQuery';
import { UserEntity } from '@nerdbord/graphql-api';
import Image from 'next/image';
import { useRouter } from 'next/router';
import styles from './Ranking.module.scss';

interface RankingItemProps extends UserEntity {
  idx: number;
}

export const RankingItem = ({
  idx,
  avatarUrl,
  username,
  rating: { hackapoints, hackathonsWon },
}: RankingItemProps) => {
  const { isMobile, isDesktop } = useMediaQuery();
  const router = useRouter();
  return (
    <>
      {isDesktop && (
        <li
          className={styles.rankingItem}
          onClick={() =>
            router.push(AppRoute.portfolio + '/' + username)
          }
        >
          <span className={styles.itemFirst}>
            {idx === 0 ? '👑' : idx + 1}
          </span>
          <div className={styles.itemSecond}>
            <Image
              src={avatarUrl || ''}
              alt="user's logo"
              width={38}
              height={38}
            />
            <p>{username}</p>
          </div>
          <p className={styles.itemThird}>{hackapoints}</p>
          <p className={styles.itemFourth}>{hackathonsWon}</p>
        </li>
      )}
      {isMobile && (
        <li
          className={styles.rankingItemMobile}
          onClick={() =>
            router.push(AppRoute.portfolio + '/' + username)
          }
        >
          <span className={styles.itemFirstMobile}>
            {idx === 0 ? '👑' : idx + 1}
          </span>
          <div className={styles.itemSecondMobile}>
            <Image
              src={avatarUrl || ''}
              alt="user's logo"
              width={38}
              height={38}
            />
            <p>{username}</p>
          </div>
          <p className={styles.itemThirdMobile}>{hackapoints}</p>
          <p className={styles.itemFourthMobile}>{hackathonsWon}</p>
        </li>
      )}
    </>
  );
};

export default RankingItem;
