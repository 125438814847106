import { PropsWithChildren } from 'react';
import * as styles from './ScheduleItemComponent.styles';

interface ScheduleItemComponentProps extends PropsWithChildren {
  title: string;
  description: string;
}

export const ScheduleItemComponent = ({
  description,
  title,
  children,
}: ScheduleItemComponentProps) => {
  return (
    <li className={styles.scheduleItem}>
      <div className={styles.infoBox}>
        <div className={styles.description}>
          <h3>{title}</h3>
          <p>{description}</p>
          {children}
        </div>
      </div>
    </li>
  );
};
