import { MediaQuery, mq } from '@/ui/styles/mediaQueries';
import { css } from '@emotion/css';

export const container = css({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#FFFFFF',
  width: '600px',
  height: '267px',
  padding: '55px',
  gap: '70px',
  cursor: 'pointer',
  [mq(MediaQuery.Mobile)]: {
    width: '100%',
    height: '100%',
    padding: '24px',
    alignItems: 'flex-start',
    gap: '22px',
  },
});

export const logoImage = css({
  width: '116px',
  height: '116px',
  [mq(MediaQuery.Mobile)]: {
    width: '66px',
    height: '66px',
  },
});

export const content = css({
  color: 'var(--Gray-5, #010101)',
  fontFamily: 'Inter, sans-serif',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '120%',
  [mq(MediaQuery.Mobile)]: {
    fontSize: '12px',
  },
});

export const wrapper = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '5px',
});

export const contentHeading = css({
  color: 'var(--Gray-5, #010101)',
  fontFamily: 'Sora, sans-serif',
  fontSize: '30px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '150%',
  whiteSpace: 'nowrap',
  [mq(MediaQuery.Mobile)]: {
    fontSize: '24px',
  },
});

export const buttonWrapper = css({
  button: {
    background: 'var(--Light-2, #FAFAF9)',
    border: '1px solid transparent',
    fontSize: '14px',
    cursor: 'pointer',
    marginTop: '10px',
    minWidth: '180px',
    '&:hover': {
      background: 'white',
    },
  },
  [mq(MediaQuery.Mobile)]: {
    button: {
      marginLeft: '-25px',
    },
  },
});
