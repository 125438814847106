import { Colors } from '@/ui/styles/colors';
import { css } from '@emotion/css';

export const scheduleItem = css({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 1,
  maxWidth: 280,
  width: '100%',
  gap: 40,
  minHeight: '265px',
});

export const step = css({
  display: 'flex',
  alignItems: 'center',
});

export const stepPoint = (isPassed: boolean) =>
  css({
    position: 'relative',
    transform: 'rotate(45deg)',
    flexShrink: 0,
    width: 23,
    height: 23,
    border: isPassed ? 'none' : `1px solid ${Colors.orange400}`,
    background: isPassed
      ? 'linear-gradient(141deg,#c32900 -22.97%,#fb5500 45.56%)'
      : '#f7f6ee',
    zIndex: 1,
  });

export const stepLine = css({
  position: 'relative',
  width: '100%',
  height: 1,
  background: Colors.orange400,
  zIndex: 0,
});

export const infoBox = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 18,
  flexGrow: 1,
});

export const deadline = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
  maxHeight: 68,
  backgroundColor: Colors.white,
  '> p': {
    padding: '15px 16px',
    color: Colors.gray800,
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '120%',
  },
});

export const description = css({
  flexGrow: 1,
  minHeight: 265,
  padding: '47px 34px 25px',
  backgroundColor: Colors.white,
  '> h3': {
    paddingBottom: 21,
    fontFamily: 'Inter',
    fontSize: 30,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '90%',
    letterSpacing: -1.8,
    background:
      'var(--gradient-2,linear-gradient(97deg, #c32900 -5.49%, #fb5500 68.62%))',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  '> p': {
    paddingBottom: 41,
    color: Colors.gray700,
    fontFamily: 'Inter',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
  },
});
