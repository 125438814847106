import { AppRoute } from '@/config/routes';
import { Container } from '@/ui/components/Container/Container';
import { useMediaQuery } from '@/ui/hooks/useMediaQuery';
import ActionButton from '@/ui/landing-v2/components/ActionButton/ActionButton';
import { WorkshopsCard } from '@/ui/landing-v2/components/WorkshopsCard/WorkshopsCard';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import styles from './CheckWorkshops.module.scss';

export const CheckWorkshops = () => {
  const { isDesktop } = useMediaQuery();
  const router = useRouter();
  const { t } = useTranslation(I18nNamespaces.Homepagev2);
  return (
    <section className={styles.mainContainer}>
      <Container>
        <div className={styles.container}>
          <div className={styles.leftSection}>
            <div className={styles.header}>
              <div>
                <h2 className={styles.title}>
                  {t('section.checkWorkshops.header1')}
                </h2>
                <h2
                  className={` ${styles.title} ${styles.titleMargin}`}
                >
                  {t('section.checkWorkshops.header2')}
                </h2>
              </div>
              <h4 className={styles.subtitleOrange}>
                {t('section.checkWorkshops.subheader')}
              </h4>
            </div>
            <div className={styles.cardsWrapper}>
              <WorkshopsCard
                imageUrl={'/images/landing-v2/github-icon.webp'}
                title={t('section.checkWorkshops.workshop1.title')}
                firstContent={t(
                  'section.checkWorkshops.workshop1.description1-1'
                )}
                secondContent={t(
                  'section.checkWorkshops.workshop1.description2'
                )}
                workshopUrl={AppRoute.g.gitWorkshop}
              />
              <WorkshopsCard
                imageUrl={'/images/landing-v2/react-icon.webp'}
                title={t('section.checkWorkshops.workshop2.title')}
                firstContent={t(
                  'section.checkWorkshops.workshop1.description1-1'
                )}
                secondContent={t(
                  'section.checkWorkshops.workshop1.description2'
                )}
                workshopUrl={AppRoute.g.reactWorkshop}
              />
              <WorkshopsCard
                imageUrl={'/images/landing-v2/bun-icon.webp'}
                title={t('section.checkWorkshops.workshop3.title')}
                firstContent={t(
                  'section.checkWorkshops.workshop1.description1'
                )}
                secondContent={t(
                  'section.checkWorkshops.workshop1.description2'
                )}
                workshopUrl={AppRoute.g.bunExperiment}
              />

              <div className={styles.buttonWrapper}>
                <ActionButton
                  onClick={() => router.push(AppRoute.workshops)}
                  variant={'primary'}
                >
                  {t('section.checkWorkshops.viewAll')}
                </ActionButton>
              </div>
            </div>
          </div>
          {isDesktop && <div className={styles.rightSection}></div>}
        </div>
      </Container>
    </section>
  );
};
