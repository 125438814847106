import { useMediaQuery } from '@/ui/hooks/useMediaQuery';
import styles from './Ranking.module.scss';

export const RankingHeader = () => {
  const { isMobile, isDesktop } = useMediaQuery();

  return (
    <>
      {isDesktop && (
        <li className={styles.rankingHeader}>
          <p className={styles.headerFirst}>Ranking</p>
          <p className={styles.headerSecond}>Nickname</p>
          <p className={styles.headerThird}>Hackapoints</p>
          <p className={styles.headerFourth}>Hackathons</p>
        </li>
      )}
      {isMobile && (
        <li className={styles.rankingHeaderMobile}>
          <p className={styles.headerFirstMobile}>Ranking</p>
          <p className={styles.headerSecondMobile}>Nickname</p>
          <p className={styles.headerThirdMobile}>HPoints</p>
          <p className={styles.headerFourthMobile}>Hackathons</p>
        </li>
      )}
    </>
  );
};
