import Image from 'next/image';

interface PartnerLogoProps {
  imageUrl: string;
  width: number;
  height: number;
}
export const PartnerLogo = ({
  imageUrl,
  width,
  height,
}: PartnerLogoProps) => {
  return (
    <Image
      width={width}
      height={height}
      src={imageUrl}
      alt={'partner logo'}
    />
  );
};
