import { MediaQuery, mq } from '@/ui/styles/mediaQueries';
import { css } from '@emotion/css';

export const container = (imagePosition: string) =>
  css({
    position: 'relative',
    width: '279px',
    height: '168px',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: '2rem 1.5rem',
    [mq(MediaQuery.Mobile)]: {
      width: '100%',
      flexDirection: imagePosition === 'left' ? 'row' : 'row-reverse',
      alignItems: 'center',
      padding: '0px',
      height: '90px',
      gap: '15px',
      paddingLeft: '-15px',
    },
  });

export const text = css({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  fontFamily: 'Inter',
  fontSize: '22px',
  fontStyle: 'normal',
  fontWeight: 600,
  letterSpacing: '-1.32px',
  background:
    'var(--gradient-2, linear-gradient(97deg, #c32900 -5.49%, #fb5500 68.62%))',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  [mq(MediaQuery.Mobile)]: {
    position: 'relative',
    fontSize: '18px',
    letterSpacing: '-1.08px',
    top: '0',
    left: '0',
    display: 'flex',
    alignSelf: 'center',
    height: 'auto',
    padding: '0 10px',
  },
});
