import { useMediaQuery } from '@/hooks/useMediaQuery';
import { PropsWithChildren } from 'react';
import * as styles from './StepComponent.styles';

interface StepComponentProps extends PropsWithChildren {
  deadline: string | null;
  isPassed: boolean;
}

export const StepComponent = ({
  isPassed,
  deadline,
}: StepComponentProps) => {
  const { isMobile, isDesktop } = useMediaQuery();
  return (
    <>
      {' '}
      {isDesktop && (
        <div className={styles.scheduleItem}>
          <div className={styles.step}>
            <div className={styles.stepPoint(isPassed)} />
            <div className={styles.stepLine} />
          </div>
          <div className={styles.infoBox}>
            <div className={styles.deadline}>
              <p>{deadline}</p>
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div className={styles.scheduleItem}>
          <div className={styles.step}>
            <span className={styles.lineLeft}></span>
            <div className={styles.step}>
              <div className={styles.stepPoint(isPassed)} />
              <div className={styles.stepLine} />
            </div>
            <span className={styles.lineSmall}></span>
          </div>
          <div className={styles.infoBox}>
            <div className={styles.deadline}>
              <p>{deadline}</p>
            </div>
          </div>
          <div className={styles.lineRight}></div>
        </div>
      )}
    </>
  );
};
