import { AppRoute } from '@/config/routes';
import ActionButton from '@/ui/landing-v2/components/ActionButton/ActionButton';
import { Ranking } from '@/ui/landing-v2/sections/common/Ranking/Ranking';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import styles from './CheckRanking.module.scss';

export const CheckRanking = () => {
  const router = useRouter();
  const { t } = useTranslation(I18nNamespaces.Homepagev2);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>
          {t('home.checkRanking.title')} <span>✨</span>
        </h2>
        <div className={styles.contentWrapper}>
          <div className={styles.description}>
            <p>
              {t('home.checkRanking.description.1')} <br />
              <br />
              {t('home.checkRanking.description.2')}{' '}
              <b>Hackapoints</b>
              {t('home.checkRanking.description.3')}
            </p>
            <div className={styles.buttonWrapper}>
              <ActionButton
                onClick={() => router.push(AppRoute.leaderboard)}
                variant="tertiary"
              >
                {t('home.checkRanking.button')}
              </ActionButton>
            </div>
          </div>
          <div className={styles.rankingWrapper}>
            <Ranking rankingLength={5} />
          </div>
        </div>
      </div>
      <Image
        className={styles.image}
        width={274}
        height={716}
        src={'/images/landing-v2/homepage-check-ranking.webp'}
        alt={'decoration'}
      />
    </div>
  );
};
