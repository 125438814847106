import { AppRoute } from '@/config/routes';
import { Container } from '@/ui/components/Container/Container';
import ActionButton from '@/ui/landing-v2/components/ActionButton/ActionButton';
import { ScheduleItemComponent } from '@/ui/landing-v2/sections/homepage/Schedule/ScheduleItemComponent/ScheduleItemComponent';
import {
  formatDateWithAddingHours,
  formatEndDate,
  formatHackathonDuration,
  isCurrDatePassedDate,
} from '@/utils/date';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useFindPublicPrimaryGroup } from '@nerdbord/graphql-api';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import styles from './Schedule.module.scss';
import { StepComponent } from './StepComponent/StepComponent';

export const Schedule = () => {
  const { t } = useTranslation(I18nNamespaces.Homepagev2);
  const { locale, push } = useRouter();
  const { group, loading } = useFindPublicPrimaryGroup();

  if (loading) return null;

  const comingSoon = t('section.consumerism.start.comingSoon');

  // 1 step component deadline with date prefix
  const formatAvailabilityEndDate = () => {
    const date = settings?.availabilityEndDate;
    if (!date) return comingSoon;
    return `${t(
      'section.schedule.datePrefix'
    )} ${formatDateWithAddingHours(date, 17, locale)}`;
  };

  // 2 step component deadline
  const formatStartAt = () => {
    const date = settings?.startAt;
    if (!date) return comingSoon;
    return formatDateWithAddingHours(date, 16, locale);
  };

  // 3 step component deadline
  const formatHackathonDurationWithCheck = () => {
    const startAt = settings?.startAt;
    const endAt = settings?.endAt;
    if (!startAt || !endAt) return comingSoon;
    return formatHackathonDuration(startAt, endAt, locale);
  };

  // 4 step component deadline with date prefix
  const formatEndAt = () => {
    const date = settings?.endAt;
    if (!date) return comingSoon;
    return `${formatEndDate(date, locale)}`;
  };

  const settings = group?.settings;
  return (
    <div id="schedule" className={styles.container}>
      <div className={styles.outsideLine}></div>
      <Container>
        <h2 className={styles.title}>
          {t('section.schedule.title')}
        </h2>
        <div className={styles.scheduleList}>
          <div className={styles.mobileBox}>
            <StepComponent
              deadline={formatAvailabilityEndDate()}
              isPassed
            />
            <ScheduleItemComponent
              title={t('section.schedule.firstItem.title')}
              description={t(
                'section.schedule.firstItem.description'
              )}
            >
              {group && (
                <ActionButton
                  onClick={() =>
                    push(AppRoute.registration.userRegistration)
                  }
                  variant="primary"
                >
                  {t('navbar.button.join')}
                </ActionButton>
              )}
            </ScheduleItemComponent>
          </div>
          <div className={styles.mobileBox}>
            <StepComponent
              deadline={formatStartAt()}
              isPassed={isCurrDatePassedDate(
                settings?.availabilityEndDate || ''
              )}
            />
            <ScheduleItemComponent
              title={t('section.schedule.secondItem.title')}
              description={t(
                'section.schedule.secondItem.description'
              )}
            />
          </div>
          <div className={styles.mobileBox}>
            <StepComponent
              deadline={formatHackathonDurationWithCheck()}
              isPassed={isCurrDatePassedDate(settings?.startAt || '')}
            />
            <ScheduleItemComponent
              title={t('section.schedule.thirdItem.title')}
              description={t(
                'section.schedule.thirdItem.description'
              )}
            />
          </div>
          <div className={styles.mobileBox}>
            <StepComponent
              deadline={formatEndAt()}
              isPassed={isCurrDatePassedDate(settings?.endAt || '')}
            />
            <ScheduleItemComponent
              title={t('section.schedule.fourthItem.title')}
              description={t(
                'section.schedule.fourthItem.description'
              )}
            />
          </div>
          <div className={styles.label}>
            <p>{t('section.schedule.label')}</p>
          </div>
        </div>
      </Container>
      <div className={styles.outsideLine}></div>
    </div>
  );
};
